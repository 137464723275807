let myChartInstance = null; // This will hold the chart instance


document.addEventListener('DOMContentLoaded', function () {
    const apiUrl = 'https://ie9wpg7jkb.execute-api.eu-west-1.amazonaws.com/records_date_range';
    const datePicker = document.getElementById('datePicker');

    datePicker.addEventListener('change', function () {
        const selectedDate = this.value; // 'this' refers to the datePicker element here
        if (!selectedDate) {
            alert("Please select a date.");
            return;
        }

        const startDate = new Date(selectedDate);
        const endDate = new Date(selectedDate);

        // Set start to 00:00:00.000
        startDate.setHours(0, 0, 0, 0);
        // Set end to 23:59:59.999
        endDate.setHours(23, 59, 59, 999);

        const startDateISOString = startDate.toISOString();
        const endDateISOString = endDate.toISOString();

        fetch(`${apiUrl}?from_datetime=${startDateISOString}&to_datetime=${endDateISOString}`)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok ' + response.statusText);
                }
                return response.json();
            })
            .then(data => {
                console.log(data); // Log data
                displayChart(data);
            })
            .catch(error => {
                console.error('There has been a problem with your fetch operation:', error);
            });
    });
});




function displayChart(data) {
    const ctx = document.getElementById('myChart').getContext('2d');

    // If there's an existing chart instance, destroy it to avoid issues when creating a new one
    if (myChartInstance) {
        myChartInstance.destroy();
    }

    const labels = data.map(record => record.received_at);
    const temperatures = data.map(record => record.temperature);
    const humidities = data.map(record => record.humidity);

    console.log(labels);
    console.log(temperatures);
    console.log(humidities);

    myChartInstance = new Chart(ctx, {
        type: 'line',
        data: {
            labels: labels,
            datasets: [{
                label: 'Average Temperature',
                data: temperatures.map((temp, index) => ({ x: labels[index], y: temp })),
                fill: false,
                borderColor: 'rgb(75, 192, 192)',
                tension: 0.1,
                yAxisID: 'temperatureAxis', // Assign to the temperature axis
            }, {
                label: 'Average Humidity',
                data: humidities.map((humidity, index) => ({ x: labels[index], y: humidity })),
                fill: false,
                borderColor: 'rgb(255, 99, 132)',
                tension: 0.1,
                yAxisID: 'humidityAxis', // Assign to the humidity axis
            }]
        },
        options: {
            scales: {
                x: {
                    type: 'time',
                    time: {
                        parser: 'yyyy-MM-dd\'T\'HH:mm:ss',
                        unit: 'hour',
                        tooltipFormat: 'HH:mm:ss'
                    },
                    title: {
                        display: true,
                        text: 'Time'
                    }
                },
                temperatureAxis: {
                    type: 'linear',
                    display: true,
                    position: 'left',
                    title: {
                        display: true,
                        text: 'Temperature (°C)'
                    }
                },
                humidityAxis: {
                    type: 'linear',
                    display: true,
                    position: 'right',
                    title: {
                        display: true,
                        text: 'Humidity (%)'
                    },
                    // Ensure the humidity axis uses a different grid but aligns its ticks with the temperature axis
                    grid: {
                        drawOnChartArea: false, // only draw the grid for this axis, not the shared grid
                    }
                }
            }
        }
    });
}
